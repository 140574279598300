.container {
  min-height: 100vh;
  background: #1B4D3E;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content {
  width: 100%;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 40px;
  text-align: center;
}

.titleArea {
  margin-bottom: 40px;
}

.title {
  font-size: 64px;
  font-weight: bold;
  color: #B8860B;
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  font-size: 32px;
  color: white;
  margin-top: 8px;
  font-weight: normal;
}

.description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 16px 0 0;
}

.features {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-bottom: 40px;
}

.featureItem {
  text-align: center;
}

.featureIcon {
  font-size: 32px;
  margin-bottom: 8px;
}

.featureText {
  color: white;
  font-size: 16px;
}

.startButton {
  background: #B8860B;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 16px 48px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 32px;
}

.startButton:hover {
  background: #986c08;
  transform: translateY(-2px);
}

.tips {
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  font-size: 14px;
}

.tips p {
  margin: 8px 0;
}

@media (max-width: 480px) {
  .container {
    padding: 16px;
  }

  .content {
    padding: 24px;
  }

  .title {
    font-size: 48px;
  }

  .subtitle {
    font-size: 24px;
  }

  .description {
    font-size: 16px;
  }

  .features {
    gap: 16px;
  }

  .featureIcon {
    font-size: 24px;
  }

  .featureText {
    font-size: 14px;
  }

  .startButton {
    padding: 12px 36px;
    font-size: 18px;
  }
} 